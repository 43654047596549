import Vue from "vue"
import App from "./App.vue"
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import VueEllipseProgress from "vue-ellipse-progress"
import vueVimeoPlayer from "vue-vimeo-player"
import VueVideoWrapper from "vue-video-wrapper"
import VueSplide from "@splidejs/vue-splide"
import VueMeta from "vue-meta"
import VueRouter from "vue-router"
import VueCarousel from "vue-carousel"

import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query"
import { showOnScroll, animationDelay, abTests } from "./utils/directives.js"

import "./custom_styles.scss"

import VueGtm from "@gtm-support/vue2-gtm"

import utils from "./utils/utils"
import constants from "./utils/constants"
import { patchTawk } from "./components/others/tawk"

import router from "./router"

import { createPinia, PiniaVuePlugin } from "pinia"

import { queryClient } from "./queries/client"

import { subscribeToGlobalEvents } from "./communication/global"

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueEllipseProgress)
Vue.use(vueVimeoPlayer)
Vue.use(VueSplide)
Vue.use(VueMeta)
Vue.use(VueVideoWrapper)
const enable_gtm = process.env.MATURAIT_ENABLE_GTM === "true"
Vue.use(VueGtm, {
  id: "GTM-KVBS5MR",
  vueRouter: router,
  enable: enable_gtm,
  loadScript: enable_gtm,
})
Vue.use(VueCarousel)

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClient,
}
Vue.use(VueQueryPlugin, vueQueryPluginOptions)

const plugin = {
  install(vue: typeof Vue) {
    vue.helpers = utils
    vue.prototype.$helpers = utils
    vue.prototype.$constants = constants
  },
}

Vue.use(plugin)

Vue.directive("showOnScroll", showOnScroll)
Vue.directive("animationDelay", animationDelay)
Vue.directive("abtest", abTests)
patchTawk()

export const EventBus = new Vue()

subscribeToGlobalEvents()

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app")
